import React, { useEffect } from 'react';
import axios from 'axios';
import { API_URL } from "../Configs/urls";

export const useRedirect = (id) => {

    useEffect(() => {
        const fetchLink = async () => {
            try {
                const shorten_link = "https://online-puromed.pl/appointment/pay/" + id;
                const response = await axios.get(`${API_URL}/payment/pay`, { params: { shorten_link } });
                if (response.data.link) {
                    window.location.href = response.data.link;
                } else {
                    console.error("No link found in response", response.data);
                }
            } catch (error) {
                console.error("Error fetching the link", error);
            }
        };

        fetchLink();
    }, [id]);
};