import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Profile from "./Sections/Profile/Profile";
import BookAppointment from "./Sections/BookApointment/BookAppointment";
import { Registration } from "./Sections/Registration/Registration";
import { Login } from "./Sections/Login/Login";
import History from "./Sections/History/History";
import ScheduledVisits from "./Sections/ScheduledVisits/ScheduledVisits";
import Results from "./Sections/Results/Results";
import Confirm from "./Sections/Confirm/Confirm";
import AppointmentDetails from "./Sections/AppointmentDetails/AppointmentDetails";
import Pay from "./Sections/Pay/Pay";

export default function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<BookAppointment />} />
          <Route path="/booking" element={<BookAppointment />} />
          <Route path="/booking/doctor/:doctorId" element={<BookAppointment />} />
          <Route path="/booking/service/:serviceId" element={<BookAppointment />} />
          <Route path="/booking/clinic/:clinicId" element={<BookAppointment />} />
          <Route path="/booking/:clinicId/:serviceId/:doctorId" element={<BookAppointment />} />
          <Route path="/appointment/details" element={<AppointmentDetails />} />
          <Route path="/appointment/confirm/:id" element={<Confirm />} />
          <Route path="/appointment/pay/:id" element={<Pay />} />

          <Route path='/profile' element={<Profile />} />
          <Route path='/results' element={<Results />} />
          <Route path='/scheduled-visits' element={<ScheduledVisits />} />
          <Route path='/history' element={<History />} />

          <Route path='/login' element={<Login />} />
          <Route path='/registration' element={<Registration />} />
        </Routes>
      </Router>
    </div>
  );
}