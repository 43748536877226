import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../Hooks/useNotify';
import { useAppointment } from '../../Hooks/useAppointment';
import { useAuthorization } from '../../Hooks/useAuthorization';

import { Page } from '../../Components/Page/Page';
import Header from '../../Components/Header/Header';
import SelectInput from '../../Components/Select/SelectInput';
import Form from '../../Components/Form/Form';
import FormRow from "../../Components/Form/FormRow";
import TextInput from '../../Components/TextInput/TextInput';
import FormRowItem from '../../Components/Form/FormRowItem';
import SidebarMenu from '../../Components/SiderbarMenu/SidebarMenu';
import Button, { ButtonType, ButtonColor, ButtonShape } from '../../Components/Button/Button';
import Checkbox from '../../Components/Checkbox/Checkbox';
import { Navbar } from '../../Components/Navbar/Navbar';
import { Footer } from '../../Components/Footer/Footer';
import { ContentCard } from '../../Components/ContentCard/ContentCard';
import ScrollableContent from '../../Components/ScrollableContent/ScrollableContent';
import Card from '../../Components/Card/Card';

const History = () => {
    const { user } = useAuthorization();
    const { contextHolder, notify } = useNotify();
    const { appointments, previousAppointment } = useAppointment();
    const { t } = useTranslation();
    const location = useLocation();


    useEffect(() => {
       const init = async () => {
            try{
                if(user.id){ const response = await previousAppointment(user.id); }
            }catch(error){
                console.log("Error(history): ", error.message);
                notify('error', "Coś poszło nie tak ...", "Niestety nie możemy pobrać listy odbytych wizyt w naszej przychodni. Za utrudnienia przepraszamy.")
            }
       };

       init();
    }, [user]);

    const pageTitle = (
        <Header
            title={t(`history`)}
            subtitle={""}
            icon={<box-icon name='history'></box-icon>}
        />
    );

    const formHeader = (
        <Header
            level={3}
            title={t(`history`)}
            subtitle={t(`history_description`)}
            icon={<box-icon name='history'></box-icon>}
        />
    );

    const mainPart = (
        <React.Fragment>
            <ContentCard>{formHeader}</ContentCard>
            <ScrollableContent header={formHeader}>
                {appointments.map((appointment, index) => (
                    <Card
                        key={index}
                        pastVisit
                        showPrice
                        appointment={appointment}
                    />
                ))}
            </ScrollableContent>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {contextHolder}
            <Navbar profileMenu navbarButton currentPath={location.pathname} />
            <Page id="history" pageTitle={pageTitle} mainPart={mainPart} sidebarMenu={<SidebarMenu currentPath={location.pathname} />} />
            <Footer />
        </React.Fragment>
    );
};

export default History;
