import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../Hooks/useNotify';
import { useAuthorization } from '../../Hooks/useAuthorization';
import { delay } from '../../Configs/utils';

import Header from '../../Components/Header/Header';
import Form from '../../Components/Form/Form';
import FormRow from "../../Components/Form/FormRow";
import TextInput from '../../Components/TextInput/TextInput';
import { IconAvatarColor } from '../../Components/IconAvatar/IconAvatar';
import Button, { ButtonType, ButtonColor, ButtonShape, ButtonSize } from '../../Components/Button/Button';
import PuromedRegistrationPhoto from "../../Resources/PuromedRegistrationPhoto.png";
import LabeledIcon from '../../Components/LabeledIcon/LabeledIcon';
import { InfoCircle } from '@vectopus/atlas-icons-react';
import { SignPage } from '../../Components/SignPage/SignPage';
import ExtraActions from '../../Components/ExtraActions/ExtraActions';

export const RegistrationForm = () => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [pesel, setPesel] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');

    const { signup } = useAuthorization();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { contextHolder, notify } = useNotify();
    
    const submit = async () => {
        try{
            if(password !== repassword) { throw new Error('Brak zgodności haseł.'); }
            const response = await signup(name, surname, pesel, login, password);
            notify('success', 'Brawo :)', 'Proces rejestracji przebiegł pomyślnie.');
            await delay(5000); navigate("/login");
        }catch(error){
            console.log('Error(register.submit)', error.message);
            notify('error', 'Coś poszło nie tak :(', 'Rejestracja użytkownika nie powiodła się. Prosimy o sprawdzenie czy oba hasła są identyczne.');
        }
    };

    const formHeader = (
        <Header
            level={3}
            title={t('sign_up')}
            subtitle={t('enter_your_data')}
        />
    );

    return (
        <Form id="registration" header={formHeader} onSubmit={submit}>
            {contextHolder}
            <FormRow>
                <TextInput label={t('name')} placeholder={t('name_input_placeholder')} value={name} onChange={(e) => setName(e.target.value)}/>
                <TextInput label={t('surname')} placeholder={t('surname_input_placeholder')} value={surname} onChange={(e) => setSurname(e.target.value)}/>
            </FormRow>
            <FormRow>
                <TextInput label={'Pesel'} placeholder={'Wprowadź numer pesel'} value={pesel} onChange={(e) => setPesel(e.target.value)}/>
            </FormRow>
            <FormRow>
                <TextInput label={t('email')} placeholder={t('email_input_placeholder')} value={login} onChange={(e) => setLogin(e.target.value)}/>
            </FormRow>
            <FormRow>
                <TextInput label={t('password')} placeholder={t('password_input_placeholder')} type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>
            </FormRow>
            <FormRow>
                <TextInput label={t("password_repeat")} placeholder={t('password_repeat_placeholder')} type='password' value={repassword} onChange={(e) => setRepassword(e.target.value)}/>
            </FormRow>
            <FormRow>
                <Button
                    type={ButtonType.Filled}
                    color={ButtonColor.Primary}
                    shape={ButtonShape.Rounded}
                    text={t("sign_up_action")}
                    submit
                />
            </FormRow>
            <FormRow>
                <ExtraActions label={t("already_have_account")} buttonLabel={t("sign_in_action")} onClick={() => navigate("/login")}/>
            </FormRow>
        </Form>
    );
}
export const Registration = () => {
    return (
        <React.Fragment>
            <SignPage form={<RegistrationForm />} photo={PuromedRegistrationPhoto} />
        </React.Fragment>
    );
}