import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from "../Configs/urls";

export const useDoctor = (clinic, service, visitType) => {
    const [doctors, setDoctors] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            const response = await axios.get(`${API_URL}/doctor/fetch`, { params: { clinic, service, visitType } }); //console.log("doctors fetch: ", response.data);
            const data = response.data.map((item) => ({ label: item.nazwa, value: item.id, })); //console.log("doctors augment: ", data);
            // data.push({label: "Dowolny", value: '0'});
            setDoctors(data);
        };

        fetch();
    }, [clinic, service, visitType]);

    return doctors;
};