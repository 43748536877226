import { notification } from "antd";

export const useNotify = () => {
    const [api, contextHolder] = notification.useNotification();

    const notify = (type, title, desc) => {
        api[type]({ message: title, description: desc });
    };

    return { contextHolder, notify };
};