import React, { useState } from "react";
import CardHeader from "./CardHeader/CardHeader";
import SelectInput from "../Select/SelectInput";
import Tag from "../Tag/Tag";
import DateTagSelect from "../DateTagSelect/DateTagSelect";

function Card({
  name,
  title,
  showPrice,
  pastVisit,
  scheduledVisit,
  appointment,
  chooseAppointment,
  handleError,
}) {

    if(scheduledVisit || pastVisit){
        const doctor_name = appointment.pracownik.imie + " " + appointment.pracownik.nazwisko;
        const doctor_title = appointment.pracownik.daneZawodowe.tytul;
        const service_name = appointment.uslugi[0].nazwa; // Assuming the first service is relevant
        const service_price = appointment.uslugi[0].dane.cena; // Assuming the price is in 'cena'
        const date = appointment.data;
        const hours = appointment.godzina; // Assuming 'hours' is 'godzina'
        
        return (
            <div className="card">
                <CardHeader name={doctor_name} title={doctor_title} />
                <SelectInput
                value={service_name}
                id="#"
                disabled={true}
                append={
                    showPrice && <Tag type="tertiary" text={service_price + " zł"} />
                }
                />
                <DateTagSelect
                date={date}
                pastVisit={pastVisit}
                scheduledVisit={scheduledVisit}
                hours={hours}
                appointment={appointment}
                chooseAppointment={chooseAppointment}
                />
            </div>
        );
    }

    try {
        // console.log("Apppointment: ",appointment);

        //TODO
        const doctor_name =
        appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].imie +
        " " +
        appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].nazwisko;
        const doctor_title =
        appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].tytul;
        const service_name =
        appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].usluga
            .nazwa;
        const service_price =
        appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].usluga
            .kwota;
        const date = appointment.dates[0].date;
        const hours =
        appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].terminy;

        return (
        <div className="card">
            <CardHeader name={doctor_name} title={doctor_title} />
            <SelectInput
            value={service_name}
            id="#"
            disabled={true}
            append={
                showPrice && <Tag type="tertiary" text={service_price + " zł"} />
            }
            />
            <DateTagSelect
            date={date}
            pastVisit={pastVisit}
            scheduledVisit={scheduledVisit}
            hours={hours}
            appointment={appointment}
            chooseAppointment={chooseAppointment}
            />
        </div>
        );
    } catch (error) {
        handleError();
        return <></>;
    }
}

export default Card;
