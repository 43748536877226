import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Tag from '../Tag/Tag';
import HourPicker from '../HourPicker/HourPicker';
import { useTranslation } from 'react-i18next';

const DateTagSelect = ({ date, pastVisit, scheduledVisit, hideCancelVisit, hour, hours, appointment, chooseAppointment }) => {
    const [showHourPicker, setShowHourPicker] = useState(false);
    const menuWrapperRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuWrapperRef.current && !menuWrapperRef.current.contains(event.target)) {
                handleCloseCalendar();
            }
        };

        if (showHourPicker) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showHourPicker]);

    const handleCloseCalendar = () => {
        setShowHourPicker(false);
    };


    let label;

    if (pastVisit) {
        label = t(`visit_date`);
    } else if (scheduledVisit) {
        label = t(`visit_date`);
    } else {
        label = t("closest_visit_date");
    }

    const dateControls = () => {

        if (pastVisit) {
            return (
                <div className='date-selector__controls date-selector__controls--past'>
                    <Tag disabled type="secondary" text={hours} />
                    <Tag type="primary" text={t(`book_visit_again`)} onClick={() => {
                        const clinicId = appointment.poradnia.id;
                        const serviceId = appointment.uslugi[0].id;
                        const doctorId = appointment.pracownik.id;
                        const path = `/booking/${clinicId}/${serviceId}/${doctorId}`;
                        navigate(path);
                    }} />
                </div>)
        }
        else if (scheduledVisit) {
            return (
                < div className='date-selector__controls date-selector__controls--scheduled' >
                    <Tag type="primary" text={hours} disabled />
                    {/* <Tag type="secondary" text={t(`edit_visit`)} /> */}
                    {/* {hideCancelVisit && <Tag id="cancel-appointment" type="secondary" text={t(`cancel_visit`)} />} */}
                </div >)
        }
        else {
            hours = hours.map(time => time.split(':').slice(0, 2).join(':'));
            return (
                <div className='date-selector__controls'>
                    <Tag type="primary" text={hours[0] ? hours[0] : '-'} onClick={() => chooseAppointment(hours[0], date, appointment)} />
                    <Tag type="primary" text={hours[1] ? hours[1] : '-'} onClick={() => chooseAppointment(hours[1], date, appointment)} />
                    <Tag type="primary" text={hours[2] ? hours[2] : '-'} onClick={() => chooseAppointment(hours[2], date, appointment)} />
                    <Tag type="secondary" text={t(`show_more`)} onClick={() => setShowHourPicker(true)} />
                </div>)
        }
        ;
    }

    return (
        <div className='date-selector-container' ref={menuWrapperRef}>
            <span className='date-selector__label'>{label}</span>
            <div className='date-selector'>
                <div className='date-info'>
                    <div className='date-info__icon'><box-icon name='time-five'></box-icon></div>
                    {date}
                </div>
                <div className='date-selector__divider'>
                </div>
                {showHourPicker && <HourPicker appointment={appointment} />}
                {dateControls()}
            </div>
        </div>
    );
};

export default DateTagSelect;
