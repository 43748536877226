import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useRedirect } from "../../Hooks/useRedirect";

export default function Pay(){
    const { id } = useParams();

    useRedirect(id);

    return <div>Loading...</div>;
}