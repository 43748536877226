import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../Hooks/useNotify';
import { useAppointment } from '../../Hooks/useAppointment';
import { useAuthorization } from '../../Hooks/useAuthorization';

import { Page } from '../../Components/Page/Page';
import Header from '../../Components/Header/Header';
import SidebarMenu from '../../Components/SiderbarMenu/SidebarMenu';
import { Navbar } from '../../Components/Navbar/Navbar';
import { Footer } from '../../Components/Footer/Footer';
import { ContentCard } from '../../Components/ContentCard/ContentCard';
import ScrollableContent from '../../Components/ScrollableContent/ScrollableContent';
import Card from '../../Components/Card/Card';
import { ClipboardTestTube } from '@vectopus/atlas-icons-react';
import { TestVial } from '@vectopus/atlas-icons-react';
import ResultCard from '../../Components/ResultCard/ResultCard';

const Results = ({ }) => {
    const { user } = useAuthorization();
    const { contextHolder, notify } = useNotify();
    const { appointments, appointmentResults } = useAppointment();
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const init = async () => {
            try{
                if(user.id){ const response = await appointmentResults(user.id); }
            }catch(error){
                console.log("Error(results): ", error.message);
                notify('error', "Coś poszło nie tak ...", "Niestety nie możemy pobrać listy wyników badań. Za utrudnienia przepraszamy.")
            }
        };
 
        init();
     }, [user]);

    const pageTitle = (
        <Header
            title={t(`results`)}
            subtitle={""}
            icon={<TestVial size={24} />}
        />
    );

    const formHeader = (
        <Header
            level={3}
            title={t(`results_list`)}
            subtitle={t(`results_description`)}
            icon={<TestVial size={24} />}
        />
    );

    const mainPart = (
        <React.Fragment>
            <ContentCard>
                {formHeader}
            </ContentCard>
            <ScrollableContent>
                {appointments.map((file, index) => (
                    <ResultCard key={file.id} date={file.dataPliku} />
                ))}
            </ScrollableContent>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {contextHolder}
            <Navbar profileMenu navbarButton currentPath={location.pathname} />
            <Page id="results" pageTitle={pageTitle} mainPart={mainPart} sidebarMenu={<SidebarMenu currentPath={location.pathname} />} />
            <Footer />
        </React.Fragment>
    );
};

export default Results;
