
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppointment } from '../../Hooks/useAppointment';
import { useNotify } from '../../Hooks/useNotify';
import { useAuthorization } from '../../Hooks/useAuthorization';

import { Page } from '../../Components/Page/Page';
import Header from '../../Components/Header/Header';
import SidebarMenu from '../../Components/SiderbarMenu/SidebarMenu';
import { Navbar } from '../../Components/Navbar/Navbar';
import { Footer } from '../../Components/Footer/Footer';
import { ContentCard } from '../../Components/ContentCard/ContentCard';
import ScrollableContent from '../../Components/ScrollableContent/ScrollableContent';
import Card from '../../Components/Card/Card';

const ScheduledVisits = ({ }) => {
    const { user } = useAuthorization();
    const { contextHolder, notify } = useNotify();
    const { appointments, upcomingAppointment } = useAppointment();
    const { t } = useTranslation();
    const location = useLocation();


    useEffect(() => {
       const init = async () => {
            try{
                if(user.id){ const response = await upcomingAppointment(user.id); }
            }catch(error){
                console.log("Error(scheduledvisits): ", error.message);
                notify('error', "Coś poszło nie tak ...", "Niestety nie możemy pobrać listy nadchodzących wizyt w naszej przychodni. Za utrudnienia przepraszamy.")
            }
       };

       init();
    }, [user]);

    
    const pageTitle = (
        <Header
            title={t(`scheduled-visits`)}
            subtitle={""}
            icon={<box-icon name='calendar' ></box-icon>}
        />
    );

    const formHeader = (
        <Header
            level={3}
            title={t(`scheduled-visits`)}
            subtitle={t(`scheduled-visits_description`)}
            icon={<box-icon name='calendar' ></box-icon>}
        />
    );

    const mainPart = (
        <React.Fragment>
            <ContentCard>
                {formHeader}
            </ContentCard>
            <ScrollableContent>
            {appointments.map((appointment, index) => (
                <Card
                    key={index}
                    scheduledVisit
                    showPrice
                    appointment={appointment}
                />
            ))}
            </ScrollableContent>
        </React.Fragment>
    );
    return (
        <React.Fragment>
            {contextHolder}
            <Navbar profileMenu navbarButton currentPath={location.pathname} />
            <Page id="scheduled-visits" pageTitle={pageTitle} mainPart={mainPart} sidebarMenu={<SidebarMenu currentPath={location.pathname} />} />
            <Footer />
        </React.Fragment>
    );
};

export default ScheduledVisits;
